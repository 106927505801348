import notify from 'devextreme/ui/notify';

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

function handleError(requestResult) {
  if(requestResult.messages) {
    for (let i = 0; i < requestResult.messages.length; i++) {
      const message = requestResult.messages[i];
      notify(message, 'error', 2000); 
    }
  }
  else{
    notify("Internal server error", 'error', 2000); 
  }
}


export { isNotEmpty, handleError };